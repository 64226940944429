import { Helmet, HelmetData } from "react-helmet-async";
import { Analytics, Choose } from "../../assets";
import { trainings } from "./constants";
import TrainingForm from "./TrainingForm";

const helmetData = new HelmetData({});

export default function Training() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Resources | Digital Analytics Training
        </title>
        <link rel="canonical" href="/digital-analytics-training" />
        <meta
          name="description"
          content="As part of our Digital Analytics Support Service, we offer comprehensive training in key analytics tools and methodologies. Our programmes empower individuals and teams to become more data-driven and make informed decisions."
        />
        <meta
          name="keywords"
          content="training, Data Analytics, Digital Analytics Training, Adobe Analytics Mastery, Google Analytics4 Expertise, Prompt Engineering for Data Analytics,  leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className=" retail-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="flex flex-col md:gap-[60px] gap-[40px]  items-center justify-center">
            <div className="w-full  items-center justify-center flex flex-col gap-2">
              <h3 className="md:text-[40px] text-center text-[28px] font-[600] gradient-h1 leading-[150%]">
                Digital Analytics Training
              </h3>
              <p className="max-w-[1000px] text-center text-white leading-[180%]">
                As part of our Digital Analytics Support Service, we offer
                comprehensive training in key analytics tools and methodologies.
                Our programmes empower individuals and teams to become more
                data-driven and make informed decisions.
              </p>
            </div>
            <div className="">
              <img src={Analytics} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container  pt-[22px]  flex flex-col  ">
          <h2 className="text-[28px]  mx-auto text-center transform-h md:text-[33px] font-[700] leading-[150%]">
            Empower Your Team with Expert Data and Digital Analytics Training
          </h2>
        </div>
        <div className="container py-[22px] md:py-10 lg:py-[96px]   grid grid-cols-1 md:grid-cols-2 gap-x-[60px] gap-y-[60px] place-items-center">
          {trainings.map((training) => (
            <div
              className="consultancyCard w-full max-w-[560px] py-[78px] px-[33px] flex flex-col items-center justify-center gap-5 md:min-h-[411px]"
              key={training.title}
            >
              <img src={training.icon} alt={training.title} />
              <h1 className="text-white text-center font-[700] text-[23px]">
                {training.title}
              </h1>
              <p className="blurry-text text-center max-w-[452px] leading-[120%]">
                {training.desc}
              </p>
            </div>
          ))}
        </div>{" "}
        <div className="container py-[40px]  lg:py-[76px] ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="w-full max-w-[464px] md:text-[33px] text-[28px] font-[600] text-poppingBlue leading-[150%]">
                Why Choose Us?
              </h3>
              <p className="w-full max-w-[532px] md:text-[18px] leading-[180%] blurry-text">
                At [AI] Analytics Intelligence, we bring years of experience in
                digital analytics across various industries. We combine industry
                expertise with virtual learning experiences to ensure you gain
                real-world skills that employers value. Join professionals who
                have transformed their careers with our trusted training
                programs.
              </p>
            </div>
            <div className="">
              <img src={Choose} alt="illustrarion" />
            </div>
          </div>
        </div>
        <TrainingForm />
        <div className="company-bg-bottom ">
          <div className="container text-center flex flex-col gap-2 md:gap-5   py-[40px] lg:py-[96px] ">
            <h1 className="gradient-header-alt md:text-[40px] font-bold leading-[150%] text-[24px] tracking-[-0.4px]">
              Ready to elevate your analytics game?
            </h1>
            <p className="px-2 w-full max-w-[900px] mx-auto blurry-text text-center font-manrope">
              Explore our courses and take the first step towards becoming a
              data-driven expert today.
            </p>
            <a href="#your-interest">
              <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
                Register Your Interest
              </button>
            </a>
          </div>
        </div>
      </main>

      <style jsx="true">
        {`
          .swiper-button-next,
          .swiper-button-prev {
            color: white;
            margin-top: -100px;
          }
          .swiper-pagination-bullet-active {
            background-color: white;
          }
        `}
      </style>
    </>
  );
}
