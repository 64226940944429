import {
  AAH,
  DAT,
  EDA,
  Fintech,
  GAE,
  IR,
  Marketing,
  PED,
  Retail,
  UUB,
} from "../../assets";
export const useCases = [
  {
    title: "Retail & E-Commerce",
    desc: "Utilise [AI] Platform to integrate generative AI features into your site, enhancing data analysis and customer interaction for optimised sales strategies.",
    image: Retail,
    path: "/retail",
  },
  {
    title: "Fintech & Finance",
    desc: "Leverage PLLM for secure generative AI applications, ensuring sensitive financial data is protected while automating expense management and reporting.",
    image: Fintech,
    path: "/fintech",
  },
  {
    title: "Marketing & Advertising",
    desc: "Employ Data Assistant for campaign management, enabling digital marketing teams to analyse performance and optimise targeting in real-time.",
    image: Marketing,
    path: "/marketing",
  },
];

export const consultancy = [
  {
    title: "Ensuring Data Accuracy",
    desc: "We leverage advanced tag management systems and AI-driven insights to ensure data accuracy for analytics and marketing. By aligning with robust data governance practices, we empower your business with organised, accessible, and reliable data for confident decision-making.",
    icon: IR,
  },
  {
    title: "Understanding User Behaviour",
    desc: "We deliver detailed insights into user interactions, website performance, and digital campaign effectiveness. By analysing behaviour patterns and engagement metrics, we help you tailor strategies to better connect with your audience and achieve meaningful results.",
    icon: UUB,
  },
  {
    title: "Optimising Conversion",
    desc: "By analysing data across multiple channels, we identify key opportunities to refine your marketing strategies. Enabling you to optimise conversion rates, enhance customer engagement, and streamline campaign performance, ultimately driving measurable results and supporting strategic decision-making.",
    icon: EDA,
  },
];

export const services = [
  {
    title: "Insight & Performance Reporting",
    desc: `We convert complex datasets into clear, actionable insights to align with your business objectives and drive success.
    <br />
    <p class="mt-2">Our team includes experts in GA4 and Adobe Analytics, ready to help you maximise these platforms by creating tailored dashboards and reports to deliver critical insights and enhance performance.</p>`,
  },
  {
    title: "Implementing Tracking and Configuring",
    desc: `Our team sets up and configures tracking systems and data layers to help you monitor and measure performance accurately.
    <br />
    <p class="mt-2">We use platforms like Tealium, ObservePoint, and Google Tag Manager to implement tracking and auditing across all digital channels.</p>`,
  },
  // {
  //   title: "AI-Ready Data Implementation",
  //   desc: "We believe the future of analytics and insight reporting lies with AI agents and assistants. We work with clients to get their data AI-ready.",
  // },
];

export const testimonials = [
  {
    title: "Digital Analytics Manager @ E-Commerce Company",
    body: "The Data Assistant has been a game-changer for us in how we manage digital analytics requests from our Digital Marketing teams. The speed and accuracy of the AI in responding to marketing analytics queries has allowed the Data and Analytics teams to focus on more deep-dive, qualitative analysis tasks.",
  },
  {
    title: "Conversion Rate Optimisation Specialist @ Insurance Company",
    body: "The Data Assistant has saved us countless hours of manual data analysis and provided us with actionable insights in real-time. This has allowed us to quickly optimise our campaigns and improve our conversion rates.",
  },
  {
    title: "Marketing Coordinator/Specialist @ Parcel Delivery Company",
    body: `"I'm able to track KPIs in real-time, allowing me to make data-driven decisions on the fly. The AI has been a game-changer for my marketing efforts."`,
  },

  {
    title: "Campaign Manager @ Digital Advertising Agency",
    body: "With the Data Assistant, I no longer have to spend hours pouring over spreadsheets. The AI gives me quick and accurate insights into my campaign's performance, freeing up my time for more creative tasks.",
  },

  {
    title: "Senior Marketing Manager @ Global Car Brand ",
    body: "As a Marketer I've always struggled with data analysis from tools like Adobe Analytics, but the Data Assistant makes it so easy! The AI provides me with quick answers to my questions, actionable insights, which has helped me improve my campaigns and achieve my goals.",
  },

  {
    title: "Director of Digital Marketing @ Lead Generation Company",
    body: "I was skeptical at first, but the Data Assistant has completely transformed my approach to marketing analytics. The AI has changed my Digital Marketing teams works, they are quicker at identifying trends and optimizing their campaigns for better results.",
  },
  {
    title: "Senior Marketing Manager @ Fintech Company",
    body: "The Data Assistant is like having my own personal analytics expert! The AI helps me understand my data in a way that makes sense and provides me with recommendations for improvement. It's made a huge difference in my digital marketing efforts.",
  },
];

export const trainings = [
  {
    title: "Decision Science Masterclass",
    desc: "In partnership with the Institute of Analytics, we offer an in-depth masterclass on decision science. Gain advanced insights into data-driven strategies, user behaviour analytics, and performance optimisation, equipping you with the tools to make informed, impactful business decisions.",
    icon: DAT,
  },
  {
    title: "Prompt Engineering for Analytics",
    desc: "Equip yourself with the skills needed for next-generation analytics. Leveraging generative AI to streamline data exploration and extract actionable insights, enhancing efficiency and decision-making with AI-driven workflows.",
    icon: AAH,
  },
  {
    title: "Adobe Analytics Mastery",
    desc: "Gain expertise in Adobe Analytics, Adobe Launch, Adobe Target, and the Adobe Experience Platform Web SDK with our specialised training, focused on leveraging custom variables for impactful, measurable results.",
    icon: GAE,
  },
  {
    title: "Google Analytics 4 Expertise",
    desc: "Master GA4 to track and analyse website performance effectively, utilising features, such as custom event creation and audience segmentation to enhance your strategies with actionable insights.",
    icon: PED,
  },
];
