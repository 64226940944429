import { Helmet, HelmetData } from "react-helmet-async";
import { Arrow_Up, Consult, Service } from "../../assets";
import { consultancy, services } from "./constants";
import ConsultationForm from "./ConsultationForm";
import Partners, { Clients } from "./partners";

const helmetData = new HelmetData({});

export default function Consultation() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Resources | Digital Analytics Support
        </title>
        <link rel="canonical" href="/digital-analytics-support" />
        <meta
          name="description"
          content="As digital transformation accelerates, data-driven decision-making is now crucial for sustained growth and a competitive edge.
          With our years of expertise in digital analytics, we support organisations across Retail, E-commerce, Fintech and Finance, Marketing and Advertising, and beyond to maximise data value."
        />
        <meta
          name="keywords"
          content="Digital Analytics Support,Insight and Performance Reporting, Implementing Tracking and Configuring, AI-Ready Data Implementation, Analytics Consultation, Customer Journey Optimisation, AI Assistant Consulting, Understand User Behaviors, Improve ROI, Ensure Data Accuracy, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className=" retail-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between xl:px-3 ">
            <div className="w-full max-w-[600px] flex flex-col gap-[30px] md:gap-[36px]">
              <h3 className="md:text-[40px] text-[28px] font-[700] gradient-h1 leading-[150%]">
                Digital Analytics Support
              </h3>
              <div className="flex flex-col gap-2">
                <p className="w-full text-base md:text-[19px] leading-[180%] font-[300]">
                  As digital transformation accelerates, data-driven
                  decision-making is now crucial for sustained growth and a
                  competitive edge.
                </p>
                <p className="w-full text-base md:text-[19px] leading-[180%] font-[300]">
                  With our years of expertise in digital analytics, we support
                  organisations across Retail, E-commerce, Fintech and Finance,
                  Marketing and Advertising, and beyond to maximise data value.
                </p>
              </div>
              <div className="flex  items-center gap-8">
                <a href="#consult-us">
                  <button className="py-3 px-6 flex justify-center items-center bg-poppingBlue text-white rounded-lg gap-2">
                    <span> Request Consultation</span>
                  </button>
                </a>
                <a href="#more">
                  <p>Learn More</p>
                </a>
              </div>
            </div>
            <div className="">
              <img src={Consult} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container flex flex-col py-[22px] md:py-10 lg:py-[96px] ">
          <h1 className="text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            Our Expertise
          </h1>
          <Partners />
        </div>
        <div className="container flex flex-col gap-3 " id="more">
          <h2 className="text-[28px] max-w-[728px] mx-auto text-center transform-h md:text-[33px] font-[700] leading-[150%]">
            Transform Your Data into Business Success
          </h2>
          <p className="w-full mx-auto text-center text-[rgba(255,255,255,0.80)]">
            With our 3-step formula, we help transform your data into business
            growth:
          </p>
        </div>
        <div className="container py-[22px] md:py-10 lg:py-[96px]   grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {consultancy.map((consult) => (
            <div
              className="consultancyCard w-full max-w-[400px] py-[54px] px-[28px] flex flex-col items-center justify-center gap-5"
              key={consult.title}
            >
              <img src={consult.icon} alt={consult.title} />
              <h1 className="text-white text-center font-[700] text-[23px]">
                {consult.title}
              </h1>
              <p className="blurry-text text-center">{consult.desc}</p>
            </div>
          ))}
        </div>
        <div className="container py-[22px] md:py-10  flex justify-between lg:items-center flex-wrap flex-col md:flex-row gap-5">
          <img src={Service} alt="Services" />
          <div className="w-full max-w-[571px] flex flex-col gap-10">
            <h1 className="w-full md:text-[33px] text-[28px] font-bold text-poppingBlue leading-[150%]">
              Our Services
            </h1>

            {services.map((serv) => (
              <div key={serv.title} className="flex flex-col gap-2">
                <div className="w-full flex gap-2">
                  <img src={Arrow_Up} alt="icon" />
                  <h1 className="text-white font-[600] text-[18px] md:text-[23px]">
                    {serv.title}
                  </h1>
                </div>
                <p
                  className="blurry-text"
                  dangerouslySetInnerHTML={{ __html: serv.desc }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="container flex flex-col py-[22px] md:py-10 lg:py-[96px] ">
          <h1 className="text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            Our Clients
          </h1>
          <Clients />
        </div>
        <ConsultationForm />
        <div className="company-bg-bottom ">
          <div className="container text-center flex flex-col gap-2 md:gap-5   py-[40px] lg:py-[96px] ">
            <h1 className="gradient-header-alt md:text-[40px] font-bold leading-[150%] text-[24px] tracking-[-0.4px]">
              Ready to Make Your Data Work for You?
            </h1>
            <p className="px-2 w-full max-w-[900px] mx-auto blurry-text text-center font-manrope">
              Optimise your customer journey and gain actionable insights with
              our comprehensive data analytics solutions.
            </p>
            <a href="#consult-us">
              <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
                Request a Free Consultation
              </button>
            </a>
          </div>
        </div>
      </main>

      <style jsx="true">
        {`
          .swiper-button-next,
          .swiper-button-prev {
            color: white;
            margin-top: -100px;
          }
          .swiper-pagination-bullet-active {
            background-color: white;
          }
        `}
      </style>
    </>
  );
}
